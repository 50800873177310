import styled from 'styled-components'
import { color } from '../../Constants/colors'

export const Container = styled.button`
  background-color: ${color.green};
  color: white;
  padding: 5px 15px;
  font-size: 13px;
  border-radius: 5px;
  outline: 0;
  border: 0; 
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${color.highlight};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`
