import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container, Nav, Content } from './styles'
import Link from '../../Atoms/Link'

const Layout = () => {
  return (
    <Container>
      <Content>
        <Outlet />
      </Content>
      <Nav>
        <Link to="/">Home</Link>
        <Link to="/privacypolicy">Privacy Policy</Link>
        <Link to="/termsandconditions">Terms of Use</Link>
        <Link to="/gdprcompliance">GDPR Policy</Link>
      </Nav>
    </Container>
  )
}

export default Layout
