import React from 'react';
import { Container, TextBlock } from './styles';
import HeaderBar from '../../Molecules/HeaderBar';
import { color } from '../../Constants/colors';

const Terms = () => {
  const termsText = [
    {
      "header": "Terms of Use",
      "body": "These Terms of Use (also referred to as the End User License Agreement or 'EULA') govern your use of the Aquarist mobile application ('App') provided by Aquarist Ltd ('Company', 'we', 'us', or 'our'). By accessing or using the App, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, you must not use the App."
    },
    {
      "header": "Use of Information",
      "body": "The information provided within the App is intended for informational purposes only. While we strive to provide accurate and up-to-date information regarding fish care, it should not be considered as professional advice. Users are encouraged to independently verify the information provided and conduct their own research before making decisions related to fish care."
    },
    {
      "header": "User Responsibility",
      "body": "Users are solely responsible for any actions taken based on the information provided within the App. The Company shall not be liable for any damages or losses resulting from the use or misuse of the information provided."
    },
    {
      "header": "No Guarantees",
      "body": "We do not guarantee the accuracy, completeness, or reliability of any information provided within the App. Users acknowledge that the information may contain errors or inaccuracies, and the Company shall not be held liable for any reliance placed on such information."
    },
    {
      "header": "Third-Party Links",
      "body": "The App may contain links to third-party websites or resources. These links are provided for convenience only, and the Company does not endorse or assume any responsibility for the content or availability of these websites or resources. Users acknowledge and agree that the Company shall not be liable for any damages or losses arising from the use of such third-party websites or resources."
    },
    {
      "header": "Intellectual Property",
      "body": "All content within the App, including but not limited to text, images, graphics, logos, and software, is the property of the Company or its licensors and is protected by intellectual property laws. Users are prohibited from reproducing, distributing, or modifying any content from the App without prior written consent from the Company."
    },
    {
      "header": "License Grant",
      "body": "The Company grants you a limited, non-exclusive, non-transferable, revocable license to use the App strictly in accordance with these Terms. You agree not to use the App for any purpose that is unlawful or prohibited by these Terms."
    },
    {
      "header": "Restrictions on Use",
      "body": "You agree not to, and will not permit others to: (a) license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose, or otherwise commercially exploit the App or make the App available to any third party; (b) modify, make derivative works of, disassemble, reverse compile, or reverse engineer any part of the App; (c) access the App in order to build a similar or competitive service."
    },
    {
      "header": "Termination",
      "body": "The Company may, in its sole discretion, at any time and for any reason, suspend or terminate your rights to use the App. Upon termination, your right to use the App will cease immediately. You understand that any termination of your account may involve the deletion of your User Content associated with your account from our live databases."
    },
    {
      "header": "Limitation of Liability",
      "body": "To the maximum extent permitted by applicable law, in no event shall the Company be liable for any indirect, punitive, incidental, special, consequential damages, or any damages whatsoever including, without limitation, damages for loss of use, data, or profits, arising out of or in any way connected with the use or performance of the App."
    },
    {
      "header": "Dispute Resolution",
      "body": "Any disputes arising out of or relating to these Terms shall be resolved by binding arbitration in accordance with the rules of the Arbitration Association in the United Kingdom. Any legal action or proceeding related to these Terms shall be brought exclusively in the courts located in the United Kingdom."
    },
    {
      "header": "App Store-Specific Terms",
      "body": "These Terms incorporate and supplement the Apple Media Services Terms and Conditions. In the event of a conflict between these Terms and the Apple Media Services Terms and Conditions, the latter shall govern."
    },
    {
      "header": "Changes to Terms",
      "body": "The Company reserves the right to modify or replace these Terms at any time. It is the responsibility of the user to periodically review the Terms for any changes. Continued use of the App after any modifications to the Terms shall constitute acceptance of those changes."
    },
    {
      "header": "Governing Law",
      "body": "These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions."
    },
    {
      "header": "Privacy Policy Reference",
      "body": "Your use of the App is also governed by our Privacy Policy. Please review our Privacy Policy, which informs users of our data collection practices."
    },
    {
      "header": "Contact Us",
      "body": (
        <>
          If you have any questions or concerns regarding these Terms, please contact us at
          <span style={{ color: color.highlight, fontWeight: 'bold' }}> hello@aquaristapp.com</span>.
        </>
      )
    }
  ];

  return (
    <Container>
      <HeaderBar />
      <TextBlock>
        {termsText.map((text, index) => (
          <React.Fragment key={index}>
            <p><strong>{text.header}</strong></p>
            <p>{text.body}</p>
          </React.Fragment>
        ))}
      </TextBlock>
    </Container>
  );
};

export default Terms;
