import React from 'react'
import { Container } from './styles'
import HeaderBar from '../../Molecules/HeaderBar'
import HomeScreen from '../../Molecules/HomeScreen'

const Home = () => {
  return (
    <Container>
      <HeaderBar />
      <HomeScreen />
    </Container>
  )
}

export default Home
