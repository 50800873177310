import React from 'react'
import { TextLink } from './styles'

const Link = ({ to, children }) => {
  return (
    <TextLink to={to}>
      {children}
    </TextLink>
  )
}

export default Link
