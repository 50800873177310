import styled from 'styled-components';
import { FRESHWATER_AM } from '../../Constants/images';
import { color } from '../../Constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;

  @media (min-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerImage = styled.div`
  display: none;

  @media (min-width: 767px) {
    display: block;
    background-image: url(${FRESHWATER_AM});
    background-size: cover;
    background-position: center right;
    min-height: 1129px;
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: -3;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  background-color: ${color.white};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0 50px 0;
  position: relative;
  z-index: 2;

  @media (min-width: 767px) {
    z-index: 1;
    background-color: ${color.whiteLowOpacity};
    position: absolute;
    min-height: 550px;
    width: 600px;
    margin: 50px 69px;
    border-radius: 30px;
    top: 0;
    left: 0;
  }
`;

export const Logo = styled.img`
  height: 70px;
  width: 267.8px;
  margin: 30px 0 10px 0;

  @media (min-width: 767px) {
    margin: 0 0 10px 0;
  }
`;

export const Header = styled.p`
  color: ${color.green};
  text-align: center;
  font-size: 1.3em;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const Body = styled.p`
  color: ${color.black};
  text-align: center;
  font-size: 1em;
  margin: 0 65px 20px 65px;
`;

export const GetTheApp = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  gap: 20px;

  @media (min-width: 767px) {
    margin: 50px 0 0 0;
  }
`;

export const ExternalLinks = styled.img`
  height: 40px;
  width: auto;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 56vh;
`;

export const Wave = styled.img`
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;

  @media (min-width: 767px) {
    display: none;
  }
`;

export const BodyImage = styled.div`
  background-image: url(${FRESHWATER_AM});
  background-size: cover;
  background-position: top center;
  height: 688px;

  @media (min-width: 767px) {
    display: none;
  }
`;
