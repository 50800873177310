import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Layout from './Organisms/Layout';
import Home from './Organisms/Home';
import Privacy from './Organisms/Privacy';
import Terms from './Organisms/Terms';
import Delete from './Organisms/Delete';
import Contact from './Organisms/Contact';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="privacypolicy" element={<Privacy />} />
        <Route path="termsandconditions" element={<Terms />} />
        <Route path="gdprcompliance" element={<Delete />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
