import styled from 'styled-components'
import { color } from '../../Constants/colors'

export const Container = styled.div`
  height: 96vh;
`

export const TextBlock = styled.div`
  padding: 20px 50px;
`

export const Heading = styled.p`
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`
