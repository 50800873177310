export const color = {
  primary: '#0E7C71',
  secondary: '#CCF3FF',
  highlight: '#B7502C',
  accent: '#D8D03F',
  blue: '#D3F2FD',
  black: '#383838',
  green: '#387A71',
  cream: '#F7F7F7',
  lowlight: '#D7D05A',
  white: '#FFFFFF',
  whiteLowOpacity: 'rgba(255, 255, 255, 0.9)',
  grey: '#E8ECEF',
}
