import React from 'react'
import { Container, TextBlock, Heading } from './styles'
import HeaderBar from '../../Molecules/HeaderBar'
import { color } from '../../Constants/colors'

const Delete = () => {
  return (
    <Container>
      <HeaderBar />
      <TextBlock>
        <Heading>Delete Specific Data In-App</Heading>
        <p>
          At any point, you can delete your aquariums, water readings, journals, and planners in-app using the delete buttons provided within each section.
        </p>

        <Heading>Delete Your Account In-App</Heading>
        <p>
          You can delete your account and all associated data directly from the Profile page in the app by using the Delete Account flow.
        </p>

        <Heading>Request Data Deletion via Email</Heading>
        <p>
          If you would like your account and any associated data to be permanently deleted from the Aquarist Ltd. servers by our team, please send an email to 
          <span style={{ color: color.highlight, fontWeight: 'bold' }}> hello@aquaristapp.com</span> with the subject line "Delete".
        </p>

        <Heading>Data We Collect and Store</Heading>
        <p>We collect and store the following information for the sole purpose of facilitating user access to our Service:</p>
        <p>• Username</p>
        <p>• Email address</p>

        <Heading>Data That Will Be Permanently Deleted</Heading>
        <p>When your account is deleted, we will also permanently delete the following data:</p>
        <p>• Logged aquariums</p>
        <p>• Planned aquariums</p>
      </TextBlock>
    </Container>
  )
}

export default Delete
