import React from 'react'
import { Container, TextBlock, EmailLink } from './styles'
import HeaderBar from '../../Molecules/HeaderBar'

const Contact = () => {
  return (
    <Container>
      <HeaderBar />
      <TextBlock>
        <p>If you have any questions or need support, please contact us at:</p>
        <EmailLink href="mailto:hello@aquaristapp.com">hello@aquaristapp.com</EmailLink>
        <p>We are here to help and will respond to your inquiries as soon as possible.</p>
      </TextBlock>
    </Container>
  )
}

export default Contact
