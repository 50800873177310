import React from 'react';
import { Container, ContainerImage, HeaderContent, Logo, Header, Body, Wave, GetTheApp, ExternalLinks, BodyImage, ImageWrapper } from './styles';
import { NAME, APPLE, GOOGLE, WAVE, PEBBLE } from '../../Constants/images';

const HomeScreen = () => {
  return (
    <Container>
      <ContainerImage />
      <HeaderContent>
        <Logo src={NAME} alt="name" />
        <Header>Your Ultimate Fishkeeping Companion</Header>
        <Body>Track tanks, plan new aquariums, read expert<br />articles, and explore a vast species database</Body>
        <Body>Enhance your fishkeeping with Aquarist!</Body>
        <GetTheApp>
          <a href="https://apps.apple.com/app/aquarist/id6501983049" target="_blank" rel="noopener noreferrer">
            <ExternalLinks src={APPLE} alt="apple" />
          </a>
        </GetTheApp>
      </HeaderContent>
      <ImageWrapper>
        <Wave src={WAVE} alt="wave" />
        <BodyImage />
      </ImageWrapper>
    </Container>
  );
};

export default HomeScreen;
