import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { color } from '../../Constants/colors';

export const TextLink = styled(Link)`
  margin: 0 7px;
  text-decoration: none;
  color: ${color.green};
  &:hover {
    color: ${color.highlight};
  }
`;
