import styled from 'styled-components';
import { color } from '../../Constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 2px ${color.grey};
  background-color: ${color.cream};
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  position: sticky;
  height: 70px;
  top: 0;
  z-index: 999;
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${color.blue};
  justify-content: center;
  border-radius: 15px;
  padding: 2px 3px 0 0;
  height: 50px;
  width: 50px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 225px;
  justify-content: space-between;
`;
