import React from 'react'
import { Container, TextBlock } from './styles'
import HeaderBar from '../../Molecules/HeaderBar'
import { color } from '../../Constants/colors'

const Privacy = () => {
  const privacyText = [
    {
      "header": "Privacy Policy",
      "body": "Aquarist Ltd. (\"us\", \"we\", or \"our\") operates Aquarist (the \"Service\"). This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.",
    },
    {
      "header": "Information Collection",
      "body": "We collect and store the following information to facilitate user access to our Service:\n• Username\n• Email address\nWe may also collect additional information automatically when you use the Service, such as device type, operating system, and usage data, to improve the user experience."
    },
    {
      "header": "Use of Data",
      "body": "We use your personal data to provide and improve the Service. Specifically, your email address is used for logging you into the app and may be used to send you email updates about the app. You can unsubscribe from these updates at any time by following the unsubscribe link in the email or contacting us directly."
    },
    {
      "header": "Data Sharing",
      "body": "We do not sell or share your personal data with third parties. However, we may disclose your personal data if required to do so by law or in response to valid requests by public authorities."
    },
    {
      "header": "Data Retention",
      "body": (
        <>
          We retain user data until the user initiates account deletion via the in-app deletion feature or submits a request for data deletion by contacting us at
          <span style={{ color: color.highlight, fontWeight: 'bold' }}> hello@aquaristapp.com</span>.
        </>
      )
    },
    {
      "header": "User Rights",
      "body": (
        <>
          Users have the right to access, correct, or delete their personal data. You can exercise these rights through the app's settings or by contacting us at
          <span style={{ color: color.highlight, fontWeight: 'bold' }}> hello@aquaristapp.com</span>. 
          We will respond to such requests in accordance with applicable data protection laws.
        </>
      )
    },
    {
      "header": "Children's Privacy",
      "body": "We do not knowingly collect personal data from children under the age of 13 without verifiable parental consent. While the app is child-friendly and has a 4+ rating, it is not specifically designed for children. If your child is under 13, we recommend that a parent or guardian create and manage the account. If we become aware that personal data from a child under 13 has been collected without parental consent, we will take steps to delete such information in compliance with applicable laws."
    },
    {
      "header": "Changes to Privacy Policy",
      "body": "The Company reserves the right to modify or replace this Privacy Policy at any time. It is the responsibility of the user to periodically review the Privacy Policy for any changes. Continued use of the App after any modifications to the Privacy Policy shall constitute acceptance of those changes."
    }
  ]

  return (
    <Container>
      <HeaderBar />
      <TextBlock>
        {privacyText.map((text, index) => (
          <React.Fragment key={index}>
            <p><strong>{text.header}</strong></p>
            <p>{text.body}</p>
          </React.Fragment>
        ))}
      </TextBlock>
    </Container>
  )
}

export default Privacy
