import React from 'react';
import { Container, Logo, ButtonGroup } from './styles';
import Button from '../../Atoms/Button';
import Link from '../../Atoms/Link';
import { LOGO } from '../../Constants/images';

const HeaderBar = () => {
  return (
    <Container>
      <Logo>
        <Link to="/">
          <img src={LOGO} style={{ height: '40px', width: 'auto' }} alt="logo" />
        </Link>
      </Logo>
      <Link to="/contact">
        <Button label="CONTACT US" />
      </Link>
    </Container>
  );
}

export default HeaderBar;
