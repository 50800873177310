import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  margin-right: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 10px 0;
  border-top: 1px solid #ccc;
`;
