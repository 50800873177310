import React from 'react'
import { Container } from './styles'

const Button = ({ label }) => {
  return (
    <Container onClick={() => {}}>
      {label}
    </Container>
  )
}

export default Button
