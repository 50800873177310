import styled from 'styled-components'
import { color } from '../../Constants/colors'

export const Container = styled.div`
  height: 96vh;
`

export const TextBlock = styled.div`
  padding: 20px 50px;
`

export const EmailLink = styled.a`
  color: ${color.highlight};
  font-weight: bold;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
